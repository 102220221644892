import "./scss/index.scss";

import { I18n } from "@lingui/react"
import * as React from "react";

import { Button, Form, TextField } from "../..";
import { maybe } from "../../../core/utils";
import { TypedCustomerRegisterMutation } from "./queries";
import { RegisterCutomer } from "./types/RegisterCutomer";

import { useSignIn } from "@sdk/react";

import { AlertManager, useAlert } from "react-alert";

const showSuccessNotification = (
  data: RegisterCutomer,
  hide: () => void,
  alert: AlertManager,
  i18n
) => {
  const successful = maybe(() => !data.customerRegister.errors.length);

  if (successful) {
    hide();
    alert.show(
      {
        title: i18n._('New user has been created'),
      },
      { type: "success" }
    );
  }
};

const RegisterForm: React.FC<{ hide: () => void }> = ({ hide }) => {
  const alert = useAlert();
  const [signIn, { loading: signInLoading }] = useSignIn();

  return (
    <I18n>
      {({i18n}) =>
        <TypedCustomerRegisterMutation
            onCompleted={data => showSuccessNotification(data, hide, alert, i18n)}
        >
          {(registerCustomer, {loading, data}) => {
            return (
              <Form
                errors={maybe(() => data.customerRegister.errors, [])}
                onSubmit={(event, {email, password}) => {
                  event.preventDefault();
                  registerCustomer({variables: {email, password}})
                    .then(response => {
                      if(response && !response.data.customerRegister.errors.length) {
                        return signIn({email, password});
                      }
                    });
                }}
              >
                <TextField
                  name="email"
                  autoComplete="email"
                  label={i18n._('Email Address')}
                  type="email"
                  required
                />
                <TextField
                  name="password"
                  autoComplete="password"
                  label={i18n._('Password')}
                  type="password"
                  required
                />
                <div className="login__content__button">
                  <Button type="submit" {...loading && {disabled: true}}>
                    {loading || signInLoading ? i18n._('Loading') : i18n._('Register')}
                  </Button>
                </div>
              </Form>
            );
          }}
        </TypedCustomerRegisterMutation>
      }
    </I18n>
  );
};

export default RegisterForm;

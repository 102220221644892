import React from "react";

import { PROVIDERS } from "../../../../core/config";
import { ProviderProps } from "../View";

const ByBill: React.FC<ProviderProps> = ({
  formRef,
  checkout,
  setLoadingState,
  processPayment,
}) => {
    const handleSubmit = async event => {
        event.preventDefault();
        setLoadingState(true);
        await checkout.update({captureAuthorized: 'Auf Rechnung'});
        await processPayment('Auf Rechnung', PROVIDERS.BY_BILL);
        setLoadingState(false);
    };

    return <form ref={formRef} onSubmit={handleSubmit}></form>;
};

export { ByBill };